import { Time } from "@angular/common";


export class EmployeeModel{
   
    id             ?: number;
    userName       ?: string;
    firstName      ?: string;
    lastName       ?: string;
    // passwordString ?: string;
    email          ?: string;
    // role           ?: string;
    password       ?: string;
    confirmPassword?: string;
    midName        ?: string;
    routeUrl       ?: string;
    name           ?: string;
    phone          ?: string;
    address        ?: string;
    birthDate      ?: string;
    departmentId   ?: number;
    cityId         ?: number;
    payrollTypeId  ?: number;
    salary         ?: number;
    tax            ?: number;
    gender         ?: string;
    isActive       ?: boolean;
    createBy       ?: string;
    // createDate     ?: Date;
    isDeleted      ?: boolean;
    // deletedDate    ?: Date;
    deletedBy      ?: string;
    managerId      ?: number;
    roleId         ?: number;
    addendenceId   ?: number;
    userId         ?: string;
    costForOverTime?: number;
    workingHourFrom?: Time;
    workingHourTo  ?: Time;
    // totalWorkTime  ?: Time;
    profileImageUri?: string;
    nationalID     ?: number;
    countryid     ?: number;
    taxPercent?:number;
    constructor(d?:EmployeeModel){
        d=d?d:{};
        this.userName=d.userName?d.userName:'';
        this.firstName=d.firstName?d.firstName:'';
        this.lastName=d.lastName?d.lastName:'';
        this.password=d.password?d.password:'';
        this.midName=d.midName?d.midName:'';
        this.routeUrl=d.routeUrl?d.routeUrl:'';
        this.address=d.address?d.address:'';
        this.birthDate=d.birthDate?d.birthDate:'';
        this.createBy=d.createBy?d.createBy:'';
        // this.createDate=d.createDate?d.createDate:new Date();
        this.deletedBy=d.deletedBy?d.deletedBy:'';
        // this.deletedDate=d.deletedDate?d.deletedDate:new Date();
        this.email=d.email?d.email:'';
        this.gender=d.gender?d.gender:'';
        this.id=d.id?d.id:0;
        this.isActive=d.isActive?d.isActive:true;
        this.isDeleted=d.isDeleted?d.isDeleted:false;
        this.name=d.name?d.name:'';
        this.phone=d.phone?d.phone:'';
        this.departmentId=d.departmentId?d.departmentId:0;
        this.cityId=d.cityId?d.cityId:0;
        this.payrollTypeId=d.payrollTypeId?d.payrollTypeId:0;
        this.salary=d.salary?d.salary:0;
        this.tax=d.tax?d.tax:0;
        this.taxPercent=d.taxPercent?d.taxPercent:0;
        this.managerId=d.managerId?d.managerId:0;
        this.managerId      =d.managerId      ?d.managerId      :0;
        this.roleId         =d.roleId         ?d.roleId         :0;
        this.addendenceId   =d.addendenceId   ?d.addendenceId   :0;
        this.userId         =d.userId         ?d.userId         :"";
        this.costForOverTime=d.costForOverTime?d.costForOverTime:0;
        this.workingHourFrom=d.workingHourFrom?d.workingHourFrom:null;
        this.workingHourTo  =d.workingHourTo  ?d.workingHourTo  :null;
        // this.totalWorkTime  =d.totalWorkTime  ?d.totalWorkTime  :null;
        this.profileImageUri=d.profileImageUri?d.profileImageUri:"";
        this.nationalID     =d.nationalID     ?d.nationalID     :0;
        this.countryid     =d.countryid     ?d.countryid     :0;
        
    }
}
