export class PatientsModel{

    id?: number;
    name?: string;
    age?: number;
    phone?: string;
    nationalID?: string;
    nots?: string;
    gender?:string;
    
     constructor(ac?: PatientsModel) {
       ac = ac ? ac : {};
       this.id = ac.id ? ac.id : 0;
       this.name=ac.name ? ac.name:'';
       this.age = ac.age ? ac.age : 0;
       this.phone=ac.phone ? ac.phone:'';
       this.nationalID=ac.nationalID ? ac.nationalID:'';
       this.nots=ac.nots ? ac.nots:'';
       this.gender=ac.gender ? ac.gender:'';
     }

}