import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PatientsModel } from './models/Patients.model';

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  
    
  }

  getAll(): Observable<PatientsModel[]> {
    debugger;
    return this.http
      .get<PatientsModel[]>(`${this.baseUrl}/patient/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createPatients(payload: PatientsModel): Observable<PatientsModel> {
    debugger;
    return this.http
      .post<PatientsModel>(`${this.baseUrl}/patient/Save`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  InsertAndReturnId(payload: PatientsModel): Observable<number> {
    debugger;
    return this.http
      .post<number>(`${this.baseUrl}/patient/InsertAndReturnId`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  put(payload: PatientsModel): Observable<PatientsModel> {
    debugger;
    console.log(payload);
    return this.http
      .post<PatientsModel>(`${this.baseUrl}/patient/Put`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: PatientsModel): Observable<PatientsModel> {
    debugger;
    return this.http
      .get<PatientsModel>(`${this.baseUrl}/patient/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  getById(id: number): Observable<PatientsModel> {
    debugger;
    const httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',

     })
   };
   return this.http
   .get<PatientsModel>(`${this.baseUrl}/patient/${id}`,this.httpOptions)
   .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

 
}
