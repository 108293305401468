import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComplaintAndSuggestionsModel } from './models/complaint-and-suggestions.model';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ComplaintAndSuggestionsService{
    baseUrl=environment.DevApiUrl;
    constructor(private http:HttpClient){}
    getAllComplaint():Observable<ComplaintAndSuggestionsModel[]>{
        return this.http
        .get<ComplaintAndSuggestionsModel[]>(`${this.baseUrl}/ComplaintAndSuggestions/GetAllComplaint`)
        .pipe(catchError((error:any)=>Observable.throw(error.json())));

    }
    getAllSuggestions():Observable<ComplaintAndSuggestionsModel[]>{
        return this.http
        .get<ComplaintAndSuggestionsModel[]>(`${this.baseUrl}/ComplaintAndSuggestions/GetAllSuggestions`)
        .pipe(catchError((error:any)=>Observable.throw(error.json())));

    }
    addComplaint(paylod:ComplaintAndSuggestionsModel):Observable<boolean>{
        debugger;
        return this.http
        .post<boolean>(`${this.baseUrl}/ComplaintAndSuggestions/InsertComplaint`,paylod)
        .pipe(catchError((error:any)=>Observable.throw(error.json())));

    }
    addSuggestions(paylod:ComplaintAndSuggestionsModel):Observable<boolean>{
        return this.http
        .post<boolean>(`${this.baseUrl}/ComplaintAndSuggestions/InsertSuggestions`,paylod)
        .pipe(catchError((error:any)=>Observable.throw(error.jason())))
    }
    getById(Id:number):Observable<ComplaintAndSuggestionsModel>{
        return this.http
        .get<ComplaintAndSuggestionsModel>(`${this.baseUrl}/ComplaintAndSuggestions/GetById/${Id}`)
        .pipe(catchError((error:any)=>Observable.throw(error.jason())));
        
    }
    delete(Id:number):void{
        this.http
        .delete(`${this.baseUrl}/ComplaintAndSuggestions/Delete/${Id}`)
        .pipe(catchError((error:any)=>Observable.throw(error.json())));
    }
    put(paylod:ComplaintAndSuggestionsModel):Observable<boolean>{
        return this.http
        .put<boolean>(`${this.baseUrl}/ComplaintAndSuggestions`,paylod)
        .pipe(catchError((error:any)=>Observable.throw(error.jason())));
    }

}
