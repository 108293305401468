import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { VacationsModel } from './models/vacations.model';

@Injectable({
  providedIn: 'root'
})
export class VacationsService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(): Observable<VacationsModel[]> {
 
    debugger;
    return this.http
      .get<VacationsModel[]>(`${this.baseUrl}/Vacations/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  

  GetAllVactionsNotApprovedForManager(): Observable<VacationsModel[]> {
 
    debugger;
    return this.http
      .get<VacationsModel[]>(`${this.baseUrl}/Vacations/GetAllVactionsNotApprovedForManager`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createVacations(payload: VacationsModel): Observable<VacationsModel> {
    
    debugger;
    return this.http
      .post<VacationsModel>(`${this.baseUrl}/Vacations/Save`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: VacationsModel): Observable<VacationsModel> {
    debugger;
    return this.http .post<VacationsModel>(`${this.baseUrl}/Vacations/Put`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: VacationsModel): Observable<VacationsModel> {
    debugger;
    return this.http .get<VacationsModel>(`${this.baseUrl}/Vacations/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  
  MangerApprove(id: number): Observable<boolean> {
  debugger;
    return this.http
    .put<boolean>(`${this.baseUrl}/Vacations/MangerApprove/${id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
   }
   CloseVacations(id: number): Observable<boolean> {
    debugger;
      return this.http .post<boolean>(`${this.baseUrl}/Vacations/CloseVacations/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
     GetAllNotApproved(): Observable<VacationsModel[]> {
 
      debugger;
      return this.http
        .get<VacationsModel[]>(`${this.baseUrl}/Vacations/GetAllNotApproved`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error.json())));
    }

    Approve(payload: VacationsModel): Observable<boolean> {
      debugger;
        return this.http
        .put<boolean>(`${this.baseUrl}/Vacations/ApproveVaction`,payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error.json())));
       }
    

}
