import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { EmployeeModel } from './models/employee.model';
import { EditEmployeeModel } from './models/edit-employee.model';
import { EmployeeRoleModel } from './models/employee-role.model';




@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  baseUrl = environment.DevApiUrl;
  constructor(private http: HttpClient) {}

  getAll(): Observable<EmployeeModel[]> {
    return this.http
      .get<EmployeeModel[]>(`${this.baseUrl}/employee/GetAll`)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllRoles(): Observable<EmployeeRoleModel[]> {
    return this.http
      .get<EmployeeRoleModel[]>(`${this.baseUrl}/employee/GetAllRoles`)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }



  post(payload: EmployeeModel): Observable<any> {
    console.log(payload);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    debugger;
    return this.http
      .post<EmployeeModel>(`${this.baseUrl}/account/AddEmployee`, payload)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createEmployee(payload: EmployeeModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    debugger;
    return this.http
      .post<EmployeeModel>(`${this.baseUrl}/employee`, payload)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: EditEmployeeModel): Observable<EditEmployeeModel> {
    return this.http .post<EditEmployeeModel>(`${this.baseUrl}/employee/Put`, payload)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  GetById(id: number): Observable<EmployeeModel> {
    debugger;
    return this.http
      .get<EmployeeModel>(`${this.baseUrl}/account/GetEmployee/${id}`)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetByIdForEdit(id: number): Observable<EditEmployeeModel> {
    debugger;
    return this.http
      .get<EditEmployeeModel>(`${this.baseUrl}/employee/${id}`)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetByDepartmentId(id: number): Observable<EmployeeModel[]> {
    debugger;
    return this.http
      .get<EmployeeModel[]>(`${this.baseUrl}/Employee/GetByDepartmentId/${id}`)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  

  delete(payload: EmployeeModel): Observable<EmployeeModel> {
    return this.http .get<any>(`${this.baseUrl}/employee/Delete/${payload.id}`)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

 
}