import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OutcomeModel } from './models/outcome.model';
import { ReportFilterModel } from '../reports/models/report-filter.model';
import { ReportByDepartmentModel } from '../reports/models/report-by-department.model';
import { ReturnDepartmentFilterModel } from '../reports/models/return-department-filter.model';

@Injectable({
  providedIn: 'root'
})
export class OutcomeService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(): Observable<OutcomeModel[]> {
 
    debugger;
    return this.http
      .get<OutcomeModel[]>(`${this.baseUrl}/Outcome/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllOutcomesForThisMonth(): Observable<OutcomeModel[]> {
 
    debugger;
    return this.http
      .get<OutcomeModel[]>(`${this.baseUrl}/Outcome/GetAllOutcomesForThisMonth`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllOutcomesForReport(payload: ReportFilterModel): Observable<OutcomeModel[]> {
 
    debugger;
    return this.http .post<OutcomeModel[]>(`${this.baseUrl}/Outcome/GetAllOutcomesForReport`,payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllForSearchByDepartment(payload: ReportByDepartmentModel): Observable<ReturnDepartmentFilterModel[]> {
 
    debugger;
    return this.http .post<ReturnDepartmentFilterModel[]>(`${this.baseUrl}/Outcome/GetAllForSearchByDepartment`,payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  createOutcome(payload: OutcomeModel): Observable<OutcomeModel> {
    
    debugger;
    return this.http
      .post<OutcomeModel>(`${this.baseUrl}/Outcome/Save`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: OutcomeModel): Observable<OutcomeModel> {
    debugger;
    return this.http
      .post<OutcomeModel>(`${this.baseUrl}/Outcome/Put`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: OutcomeModel): Observable<OutcomeModel> {
    debugger;
    return this.http
      .get<OutcomeModel>(`${this.baseUrl}/Outcome/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  GetByStudyPhaseId(id: number): Observable<OutcomeModel[]> {
  
   return this.http
   .get<OutcomeModel[]>(`${this.baseUrl}/Outcome/GetByStudyPhaseId/${id}`,this.httpOptions)
   .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

 
}
