export class ComplaintAndSuggestionsModel{
    id?:number;
    title?:string;
    description?:string;
    type?:number;
    userId?:string;
    userName?:string;
    createBy?:string;
    isDeleted?:boolean;
    deletedBy?:string;
    isAbroved?:boolean;
    isClosed?:boolean;
    constructor(c?:ComplaintAndSuggestionsModel){
        c=c?c:{};
        this.createBy=c.createBy?c.createBy:'';
        this.deletedBy=c.deletedBy?c.deletedBy:'';
        this.description=c.description?c.description:'';
        this.id=c.id?c.id:0;
        this.isDeleted=c.isDeleted?c.isDeleted:false;
        this.title=c.title?c.title:'';
        this.type=c.type?c.type:0;
        this.userId=c.userId?c.userId:'';
        this.userName=c.userName?c.userName:'';
        this.isAbroved=c.isAbroved?c.isAbroved:false;
        this.isClosed=c.isClosed?c.isClosed:false;

    }

}