import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PharmaceuticalModel } from './models/pharmaceutical.model';

@Injectable({
  providedIn: 'root'
})
export class PharmaceuticalService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  
    
  }

  getAll(): Observable<PharmaceuticalModel[]> {
    debugger;
    return this.http
      .get<PharmaceuticalModel[]>(`${this.baseUrl}/Pharmaceutical/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getAllForCurrentUser(): Observable<PharmaceuticalModel[]> {
    debugger;
    return this.http
      .get<PharmaceuticalModel[]>(`${this.baseUrl}/Pharmaceutical/GetAllForCurrentUser`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createPharmaceutical(payload: PharmaceuticalModel): Observable<PharmaceuticalModel> {
    debugger;
    return this.http
      .post<PharmaceuticalModel>(`${this.baseUrl}/Pharmaceutical/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: PharmaceuticalModel): Observable<PharmaceuticalModel> {
    debugger;
    return this.http .post<PharmaceuticalModel>(`${this.baseUrl}/Pharmaceutical/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: PharmaceuticalModel): Observable<PharmaceuticalModel> {
    debugger;
    return this.http .delete<PharmaceuticalModel>(`${this.baseUrl}/Pharmaceutical/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  getById(id: number): Observable<PharmaceuticalModel> {
    debugger;
    const httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',

     })
   };
   return this.http
   .get<PharmaceuticalModel>(`${this.baseUrl}/Pharmaceutical/${id}`,this.httpOptions)
   .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

 
}
