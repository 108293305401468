import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IncomeModel } from './models/income.model';
import { ReportFilterModel } from '../reports/models/report-filter.model';
import { ReportByDepartmentModel } from '../reports/models/report-by-department.model';
import { ReturnDepartmentFilterModel } from '../reports/models/return-department-filter.model';

@Injectable({
  providedIn: 'root'
})
export class IncomeService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(): Observable<IncomeModel[]> {
 
    debugger;
    return this.http
      .get<IncomeModel[]>(`${this.baseUrl}/Income/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllIncomesForThisMonth(): Observable<IncomeModel[]> {
 
    debugger;
    return this.http
      .get<IncomeModel[]>(`${this.baseUrl}/Income/GetAllIncomesForThisMonth`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllIncomesForFilter(payload: ReportFilterModel): Observable<IncomeModel[]> {
 console.log(payload);
    debugger;
    return this.http .post<IncomeModel[]>(`${this.baseUrl}/Income/GetAllIncomesForFilter`,payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetAllForSearchByDepartment(payload: ReportByDepartmentModel): Observable<ReturnDepartmentFilterModel[]> {
    console.log(payload);
       debugger;
       return this.http
         .post<ReturnDepartmentFilterModel[]>(`${this.baseUrl}/Income/GetAllForSearchByDepartment`,payload,this.httpOptions)
         .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
  
  createIncome(payload: IncomeModel): Observable<IncomeModel> {
    
    debugger;
    return this.http
      .post<IncomeModel>(`${this.baseUrl}/Income/Save`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: IncomeModel): Observable<IncomeModel> {
    debugger;
    return this.http
      .post<IncomeModel>(`${this.baseUrl}/Income/Put`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: IncomeModel): Observable<IncomeModel> {
    debugger;
    return this.http
      .get<IncomeModel>(`${this.baseUrl}/Income/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  GetByStudyPhaseId(id: number): Observable<IncomeModel[]> {
  
   return this.http
   .get<IncomeModel[]>(`${this.baseUrl}/Income/GetByStudyPhaseId/${id}`,this.httpOptions)
   .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

 
}
