import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AssetsTypeModel } from './models/assets-type.model';

@Injectable({
  providedIn: 'root'
})
export class AssetsTypeService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(): Observable<AssetsTypeModel[]> {
 
    debugger;
    return this.http
      .get<AssetsTypeModel[]>(`${this.baseUrl}/AssetsType/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  createAssetsType(payload: AssetsTypeModel): Observable<AssetsTypeModel> {
    
    debugger;
    return this.http
      .post<AssetsTypeModel>(`${this.baseUrl}/AssetsType/Save`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  put(payload: AssetsTypeModel): Observable<AssetsTypeModel> {
    debugger;
    return this.http
      .post<AssetsTypeModel>(`${this.baseUrl}/AssetsType/Put`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: AssetsTypeModel): Observable<AssetsTypeModel> {
    debugger;
    return this.http
      .get<AssetsTypeModel>(`${this.baseUrl}/AssetsType/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  GetByStudyPhaseId(id: number): Observable<AssetsTypeModel[]> {
  
   return this.http
   .get<AssetsTypeModel[]>(`${this.baseUrl}/AssetsType/GetByStudyPhaseId/${id}`,this.httpOptions)
   .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

 
}
