export class PharmaceuticalCompanyUserModel{
    id?: number;
    userName       ?: string;
    firstName      ?: string;
    lastName       ?: string;
    email          ?: string;
    profileImageUri?: string;
    role           ?: string;
    password       ?: string;
    confirmPassword?: string;
    midName        ?: string;
    name           ?: string;
    birthDate      ?: Date;
    address        ?: string;
    phone1         ?: string;
    phone2         ?: string;
    companyId      ?: number;
    gender         ?: string;
    oldPassword    ?: string;
    userId         ?: string;
    constructor(user ?: PharmaceuticalCompanyUserModel){
        user = user ? user :{};
        this.id = user.id ? user.id : 0;
        this.userName        = user.userName        ? user.userName        : '';
        this.firstName       = user.firstName       ? user.firstName       : '';
        this.lastName        = user.lastName        ? user.lastName        : '';
        this.email           = user.email           ? user.email           : '';
        this.profileImageUri = user.profileImageUri ? user.profileImageUri : '';
        this.role            = user.role            ? user.role            : '';
        this.password        = user.password        ? user.password        : '';
        this.confirmPassword = user.confirmPassword ? user.confirmPassword : '';
        this.midName         = user.midName         ? user.midName         : '';
        this.name            = user.name            ? user.name            : '';
        this.birthDate       = user.birthDate       ? user.birthDate       : null;
        this.address         = user.address         ? user.address         : '';
        this.phone1          = user.phone1          ? user.phone1          : '';
        this.phone2          = user.phone2          ? user.phone2          : '';
        this.companyId       = user.companyId       ? user.companyId       : 0;
        this.gender          = user.gender          ? user.gender          : '';
        this.oldPassword     = user.oldPassword     ? user.oldPassword     : '';
        this.userId          = user.userId          ? user.userId          : '';
    }
}