import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PharmaceuticalCompanyUserModel } from './models/pharmacwutical-company-user.model';
import { PharmaceuticalCompanyUserForGetModel } from './models/pharmacwutical-company-user-for-get.model';
@Injectable({
  providedIn: 'root'
})
export class PharmaceuticalCompanyUserService {
  baseUrl = environment.DevApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 };
  constructor(private http: HttpClient) {}

  GetByCompanyId(companyId : number): Observable<PharmaceuticalCompanyUserForGetModel[]> {
    return this.http
      .get<PharmaceuticalCompanyUserForGetModel[]>(`${this.baseUrl}/PharmaceuticalCompanyUsers/GetByCompanyId/${companyId}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetForAdminCompany(): Observable<PharmaceuticalCompanyUserForGetModel[]> {
    return this.http
      .get<PharmaceuticalCompanyUserForGetModel[]>(`${this.baseUrl}/PharmaceuticalCompanyUsers/GetForAdminCompany`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  createUser(payload: PharmaceuticalCompanyUserModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    debugger;
    return this.http
      .post<PharmaceuticalCompanyUserModel>(`${this.baseUrl}/account/SavePharmaceuticalCompanyUser`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

}